// Store ID
const account = 'smrholding'
const production = 'smrholding'

// SalesChannels
const salesChannels = {
  default: 1,
  club: 6,
}

// Chat URL
const chatUrl =
  'https://chat.directtalk.com.br/static/?tenantId=ce7bcd84-2c17-4f58-a071-d9aa9cdc9b6c&templateId=06ce85c3-898f-4684-a90b-b52e58b8a768&departmentId=e06b81bf-6952-4e2b-a957-de76fc263198'

const salesForceActivated = true

// GTM-IDs
const gtmIdTest = 'GTM-5JJCDXDJ'
const gtmIdProduction = 'GTM-5JJCDXDJ'

module.exports = {
  account,
  production,

  // Ecommerce Platform
  platform: 'vtex',

  experimental: {
    nodeVersion: 14,
  },

  // Platform specific configs for API
  api: {
    storeId: account,
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  // Default channel
  session: {
    currency: {
      code: 'BRL',
      symbol: 'R$',
    },
    locale: 'pt-br',
    channel: `{"salesChannel":"${salesChannels.default}","regionId":""}`,
    country: 'BRA',
    postalCode: null,
    person: null,
  },

  /*
    https://loja.smrholding.com
    https://secure.smrholding.com
  */
  // Production URLs
  storeUrl: `https://loja.smrholding.com`,
  secureSubdomain: `https://secure.smrholding.com`,
  checkoutUrl: `https://secure.smrholding.com/api/io/cart`,
  loginUrl: `https://secure.smrholding.com/api/io/login`,
  accountUrl: `https://secure.smrholding.com/api/io/account`,
  chatUrl,
  salesForceActivated,

  // Jitterbit flows urls
  jitterbitUrls: {
    createUser:
      'https://smrholding.wevo.io/iomanager/api/flows/execute/route/user/create',
  },

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      home: '/',
      pdp: '/abobora-pre-cozida-benassi-1kg-12/p',
      collection:
        '/alimentos/hortifruti/legumes-e-vegetais/processados-e-fracionados/',
    },
  },

  // SalesChannels
  salesChannels,

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/abobora-pre-cozida-benassi-1kg-12/p',
      collection:
        '/alimentos/hortifruti/legumes-e-vegetais/processados-e-fracionados',
      collection_filtered:
        '/alimentos/hortifruti/legumes-e-vegetais/processados-e-fracionados/?brand=hortifruti&category-1=alimentos&category-2=hortifruti&category-3=legumes-e-vegetais&category-4=processados-e-fracionados&facets=brand%2Ccategory-1%2Ccategory-2%2Ccategory-3%2Ccategory-4&sort=score_desc&page=0',
      search: '/s/?q=abóbora%20pré%20cozida',
    },
  },

  hotjarId: '3312211',

  crazyEgg: true,

  // Meta Tags
  metaTags: {
    'google-site-verification': '',
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId:
      process.env.FASTSTORE_ENV === 'production' ? gtmIdProduction : gtmIdTest,
  },
}
