import { useSession } from '@plurix/ecom-pages/src/sdk/session'
import { mark } from '@plurix/ecom-pages/src/sdk/tests/mark'
import { Redirect } from '@reach/router'
import axios from 'axios'
import { graphql } from 'gatsby'
import {
  BreadcrumbJsonLd,
  GatsbySeo,
  ProductJsonLd,
} from 'gatsby-plugin-next-seo'
import React from 'react'
import { NpmProductPage } from 'src/local_modules'

import storeConfig from '../../../store.config'
import { useAllCollections } from '../../hooks/useAllCollections'

import type {
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
} from '@generated/graphql'
import type { ProductsQueryResponse } from '@plurix/ecom-pages/src/types/Product'
import type { PageProps } from 'gatsby'

export type Props = PageProps<
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  unknown,
  { slug: string; product: ProductsQueryResponse['node'] }
>

function removeSkuinSlug(slug: string) {
  const arraySlug = slug.split('-')

  arraySlug.pop()

  return `${arraySlug.join('-')}`
}

const salesChannels = Object.values(storeConfig.salesChannels).join(',')

function Page(props: Props) {
  const {
    data: { site },
    location: { host },
    serverData: { slug, product },
  } = props

  const { locale, currency } = useSession()
  const { loading, collections } = useAllCollections()

  // eslint-disable-next-line prefer-destructuring
  const pdpCluster = collections[5]

  if (!slug || !product) {
    return <Redirect to="/404" noThrow />
  }

  const title = product?.seo
    ? product.seo.title
    : site?.siteMetadata?.title ?? ''

  const description = product?.seo
    ? product.seo.description
    : site?.siteMetadata?.description ?? ''

  const canonical =
    host !== undefined ? `https://${host}/${slug}/p` : `/${slug}/p`

  const availability =
    product.offers?.offers[0]?.availability === 'InStock'
      ? 'InStock'
      : 'OutOfStock'

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'og:product',
          url: `${site?.siteMetadata?.siteUrl}${slug}`,
          title,
          description,
          images: product.image.map((img) => ({
            url: img.url,
            alt: img.alternateName,
          })),
        }}
        metaTags={[
          {
            property: 'product:price:amount',
            content: product.offers.lowPrice?.toString() ?? undefined,
          },
          {
            property: 'product:price:currency',
            content: currency.code,
          },
        ]}
      />

      <BreadcrumbJsonLd
        itemListElements={product?.breadcrumbList?.itemListElement ?? []}
      />

      <ProductJsonLd
        name={product.name}
        description={product.description}
        brand={product.brand.name}
        sku={product.sku}
        gtin={product.gtin}
        images={product.image.map((img) => img.url)} // Somehow, Google does not understand this valid Schema.org schema, so we need to do conversions
        offersType="AggregateOffer"
        offers={{
          priceCurrency: 'BRL',
          availability,
          seller: {
            name: product.offers.offers[0].seller.identifier,
          },
          price: product.offers.offers[0].price.toString(),
        }}
      />
      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      <NpmProductPage
        loading={loading || !product}
        cluster={pdpCluster}
        slug={slug}
      />
    </>
  )
}

export const querySSG = graphql`
  query ProductPageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
  }
`
type GetServerDataProps = {
  params: {
    slug: string
  }
}

export const getServerData = async ({
  params: { slug },
}: GetServerDataProps) => {
  const ONE_YEAR_CACHE = `s-maxage=31536000, stale-while-revalidate`
  const newSlug = removeSkuinSlug(slug)
  const url =
    `https://${storeConfig.account}.myvtex.com/_v/commerce/products/productDetails?` +
    `slug=${newSlug}&` +
    `salesChannels=${salesChannels}&` +
    `activeSalesChannel=${storeConfig.salesChannels.default}`

  try {
    const { data } = await axios.get(url)

    return {
      status: 200,
      props: { product: data.product, slug: newSlug },
      headers: {
        'cache-control': ONE_YEAR_CACHE,
      },
    }
  } catch (error) {
    const params = new URLSearchParams({
      from: encodeURIComponent(`/${newSlug}/p`),
    })

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': ONE_YEAR_CACHE,
        location: `/404/?${params.toString()}`,
      },
    }
  }
}

Page.displayName = 'PDP'

export default mark(Page)
